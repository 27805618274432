export default [
  '白羊座',
  '处女座',
  '金牛座',
  '巨蟹座',
  '摩羯座',
  '射手座',
  '狮子座',
  '双子座',
  '双鱼座',
  '水瓶座',
  '天秤座',
  '天蝎座'
];