import axios from 'axios'


axios.defaults.baseURL = 'http://8.134.171.247:8080'

function axiosGet(options){
    axios(options.url)
        .then((res)=>{
            options.success(res);
            
        })
        .catch((err)=>{
            options.error(err);
        })
}

export {
    axiosGet
}