<template>
    <div class="container">
      <ConsCard
        :name="todayData.name"
        :allIndex="todayData.all"
      />
      <NumList :data="todayData" />
      <ConsList :data="todayData" />
    </div>
  </template>
  
  <script setup>
  
  import { onMounted, computed, ref, onActivated, toRaw } from 'vue';
  import { useStore } from 'vuex';
  import getData from '@/services';
  
  import ConsCard from '@/components/common/Card.vue';
  import NumList from '@/components/NumList';
  import ConsList from '@/components/List/Today';
  
  const store = useStore(),
        state = store.state,
        status = ref('');
  
  const todayData = computed(() => {
    if(state.today) return state.today;
  })


  onMounted(() => {
    getData(store);
    status.value = state.consName;
    
  });

  onActivated(() => {   //当keepalive组件重新插入时，触发此函数并且重新更新store里面的state
    if (status.value !== state.consName) {
      getData(store);
      status.value = state.consName;
    }
  })
  
  </script>
  
  <style lang="scss" scoped>
  
  </style>