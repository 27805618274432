<template>
  <div id="app">
    <my-header>星座物语</my-header>
    <NavBar />
    <ErrorTip />
    <router-view v-slot="{ Component }" v-if="!errorCode">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
    <tab />
  </div>
</template>

<script setup>
  import MyHeader from '@/components/Header';
  import Tab from '@/components/Tab';
  import NavBar from '@/components/NavBar';
  import ErrorTip from '@/components/ErrorTip';
  
  import { watch, computed } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';

  
  const store = useStore(),
        state = store.state,
        router = useRouter();
  const  errorCode = computed(() => state.errorCode)

  
  router.push('/');
  store.commit('setField', 'today');
  
  watch(() => {    //状态监听  
    return router.currentRoute.value.name;
  }, (value) => {
    store.commit('setField', value);
  });

</script>
