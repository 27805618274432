export default {
  "217701": "未搜索到数据",
  "217702": "输入的日期格式错误",
  "10001": "无请求权限",
  "10002": "无请求权限",
  "10003": "无请求权限",
  "10004": "无请求权限",
  "10005": "无请求权限",
  "10007": "未知的请求源",
  "10008": "IP被禁止使用该功能",
  "10009": "无请求权限",
  "10011": "超过搜索数据限制次数",
  "10012": "超过搜索数据限制次数",
  "10013": "超过搜索数据限制次数",
  "10014": "系统正在维护",
  "10020": "系统正在维护",
  "10021": "系统正在维护",
};
