import { axiosGet } from "@/libs/http";

function getData(consName, type){
    return new Promise((resolve, reject)=>{
        axiosGet({
            url: `/constellation?type=${type}&consName=${consName}`,
            success: (res)=>{
                const data = res.data;
                resolve(data);
                
            },
            error: (err)=>{
                reject(err)
            }
        })
    })
}

export {
    getData
}